import { toast } from "react-hot-toast";

export class ClientToast {
  static error = (message: string) => {
    toast.custom(
      <div className="rounded-md border  border-red-900 bg-red-100 p-4 text-red-900">
        {message}
      </div>,
      {
        duration: 4000,
      }
    );
  };

  static success = (message: string) => {
    toast.custom(
      <div className="rounded-md border  border-green-900 bg-brand-mint_green-50 p-4 text-green-900">
        {message}
      </div>,
      {
        duration: 4000,
      }
    );
  };

  static warn = (message: string) => {
    toast.custom(
      <div className="rounded-md border  border-yellow-600 bg-yellow-100 p-4 text-gray-900">
        {message}
      </div>,
      {
        duration: 4000,
      }
    );
  };

  static info = (message: string) => {
    toast.custom(
      <div className="rounded-md border  border-gray-500 bg-white p-4 text-gray-900">
        {message}
      </div>,
      {
        duration: 4000,
      }
    );
  };
}
